<!-- <div @mousewheel="handleScroll"> -->
<template>
  <div style="position: relative;">
    <div class="barnner"></div>
    <div class="box titleinside">
      <div class="nav_ titleinside_child">
        <div v-for="item in list" :key="item.id + '1'" class="from_list" @click="title_btn(item.id)"
          :class="{ click_sty: clickId == item.id }">
          {{ item.title }}
          <div v-if="clickId == item.id" style="position: relative; top:-6px;">
            <div style="background:#e1251b; height:3px;"></div>
            <div style="background:#e3bb58; height:3px;"></div>
          </div>
        </div>
      </div>
      <div class="nav_1" ref="title">
        <div v-for="item in list" :key="item.id + '2'" class="from_list" @click="title_btn(item.id)"
          :class="{ click_sty: clickId == item.id }">
          {{ item.title }}
          <div v-if="clickId == item.id" style="position: relative; top:-6px;">
            <div style="background:#e1251b; height:3px;"></div>
            <div style="background:#e3bb58; height:3px;"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="boundary">
      <div class="body_right">
        <!-- 企业介绍 -->
        <div style="height:1000px">
          <div style="text-align: center; margin:70px 0 32px 0;font-weight: 500; font-size: 40px;">
            企业介绍
          </div>
          <centerS></centerS>
          <div style="margin:50px 0 0 0;font-size: 17px;line-height: 32px;font-weight: 400;">
            浙江中峪控股集团有限公司
            是一家涉足商贸、拍卖交易所、职业培训学校、中医大健康、医疗、生物制药、网络科技、养老产业、金融等十大闭环产业的集团化公司，集团以国家政策为导向，以大众创业，万众创新为初心，跨界融合、科技创新、数字经济、跨产业赋能、多元化经营、服务于各生产企业的同时，为市场培育孵化更多优质的人才。同时紧跟国家战略部署，以乡村振兴为战略目标；以传承弘扬发展中医文化为使命，把中医精髓代代相传并发扬光大，普惠大众；以振兴地区职业技能，培育多元化技术人才，助推就业问题；为国家大健康战略规划真正落地生根普及，为提升老年人生活品质等民生领域，贡献自己的力量。

            <div style="position: relative;">
              <img style="width:100%; padding:32px 0;" src="../../../static/newImage/barnner123.jpg" />
              <div class="play_video" @click="showVideoFun"></div>
            </div>
          </div>
        </div>
        <!-- 行业地位 -->
        <div style="height:300px">
          <div style="text-align: center; margin:70px 0 32px 0;font-weight: 500; font-size: 40px;">
            行业地位
          </div>
          <centerS></centerS>
          <div style="margin:50px 0 0 0;font-size: 17px;line-height: 32px;font-weight: 400;">
            极具前瞻性、战略性、智能化，在线化、网络化、去中心化、数据科技技术化。结合国家宏观政策和微观民生需求，做一个万物互联的产业链，实现线上线下联动运营，打造天地人网的产业格局，同时要实现数据智能加商业场景无缝融合的企业产品效应。借助麒典信息所具备的技术创新、数字数据优势，为闭环产业链内提供了驱动力、安全性，重构了整个商业逻辑的诞生的运营规律，使消费群体实现了整体的改变，同时商家拓客、锁客的模式进行了转型升级改变。数据智能私域化，实现精准广告、精准营销、精准零售、精准品宣。通过才、材、方、科技产品、文化的五位一体的产业融合，实现未来医养（银发）产业的推进和实施。
          </div>
        </div>
        <!-- 企业文化 -->
        <div style="height:640px">
          <div style="text-align: center; margin:70px 0 32px 0;font-weight: 500; font-size: 40px;">
            企业文化
          </div>
          <centerS></centerS>
          <div style="margin-top:65px;position: relative;">
            <div class="imgs">
              <div class="imgs_item" :class="imgIndex == 0 ? ' active' : ''" @click="imgIndex = 0" data-index="0"
                @mouseover="imgIndex = 0"
                :style="'background-image: url(' + require('../../../static/newImage/img99.jpg') + ');background-repeat: no-repeat;background-size: cover;'"
                alt=""></div>
              <div class="imgs_item" :class="imgIndex == 1 ? ' active' : ''" @click="imgIndex = 1" data-index="1"
                @mouseover="imgIndex = 1"
                :style="'background-image: url(' + require('../../../static/newImage/img98.jpg') + ');background-repeat: no-repeat;background-size: cover;'"
                alt=""></div>
              <div class="imgs_item" :class="imgIndex == 2 ? ' active' : ''" @click="imgIndex = 2" data-index="2"
                @mouseover="imgIndex = 2"
                :style="'background-image: url(' + require('../../../static/newImage/img97.jpg') + ');background-repeat: no-repeat;background-size: cover;'"
                alt=""></div>
              <div class="imgs_item" :class="imgIndex == 3 ? ' active' : ''" @click="imgIndex = 3" data-index="3"
                @mouseover="imgIndex = 3"
                :style="'background-image: url(' + require('../../../static/newImage/img96.jpg') + ');background-repeat: no-repeat;background-size: cover;'"
                alt=""></div>
              <div class="imgs_item" :class="imgIndex == 4 ? ' active' : ''" @click="imgIndex = 4" data-index="4"
                @mouseover="imgIndex = 4"
                :style="'background-image: url(' + require('../../../static/newImage/img95.jpg') + ');background-repeat: no-repeat;background-size: cover;'"
                alt=""></div>
            </div>
            <div class="imgs_tips">
              <div class="imgs_tips_item" :class="imgIndex == 0 ? ' active' : ''" @click="imgIndex = 0" data-index="0"
                @mouseover="imgIndex = 0">
                <div class="imgs_tips_item_bg" v-show="imgIndex != 0">文化理念</div>
                <div v-show="imgIndex == 0" style="width: 452px;">
                  <div style="font-size: 24px;color: #fff;">文化理念</div><br />
                  <div style="font-size: 20px;color: #fff;">至专至精 财富共赢</div>
                </div>
              </div>
              <div class="imgs_tips_item" :class="imgIndex == 1 ? ' active' : ''" @click="imgIndex = 1" data-index="1"
                @mouseover="imgIndex = 1">
                <div class="imgs_tips_item_bg" v-show="imgIndex != 1">合作精神</div>
                <div v-show="imgIndex == 1" style="width: 452px;">
                  <div style="font-size: 24px;color: #fff;">合作精神</div><br />
                  <div style="font-size: 20px;color: #fff;">持诚信互利共荣 以远见共谋发展</div>
                </div>
              </div>
              <div class="imgs_tips_item" :class="imgIndex == 2 ? ' active' : ''" @click="imgIndex = 2" data-index="2"
                @mouseover="imgIndex = 2">
                <div class="imgs_tips_item_bg" v-show="imgIndex != 2">员工理念</div>
                <div v-show="imgIndex == 2" style="width: 452px;">
                  <div style="font-size: 24px;color: #fff;">员工理念</div><br />
                  <div style="font-size: 20px;color: #fff;">专业进取 锲而不舍</div>
                </div>
              </div>
              <div class="imgs_tips_item" :class="imgIndex == 3 ? ' active' : ''" @click="imgIndex = 3" data-index="3"
                @mouseover="imgIndex = 3">
                <div class="imgs_tips_item_bg" v-show="imgIndex != 3">企业用人观</div>
                <div v-show="imgIndex == 3" style="width: 452px;">
                  <div style="font-size: 24px;color: #fff;">企业用人观</div><br />
                  <div style="font-size: 20px;color: #fff;">以德为首 以智为本 以诚为准</div>
                </div>
              </div>
              <div class="imgs_tips_item" :class="imgIndex == 4 ? ' active' : ''" @click="imgIndex = 4" data-index="4"
                @mouseover="imgIndex = 4">
                <div class="imgs_tips_item_bg" v-show="imgIndex != 4">员工训词</div>
                <div v-show="imgIndex == 4" style="width: 452px;">
                  <div style="font-size: 24px;color: #fff;">员工训词</div><br />
                  <div style="font-size: 20px;color: #fff;">以严谨的态度做事 用感恩的心做人</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div style="text-align: center; margin:80px 0 32px 0;font-weight: 500; font-size:40px">
            联系我们
          </div>
          <centerS></centerS>
          <div class="contact_address">
            <div class="threeBox">
              <img style="width:40px;height:35px;" src="../../../static/newImage/consult.png" />
              <div class="type">
                &nbsp;产品咨询
              </div>
              <div class="meCenter">
                如果您对我们的产品感兴趣，想深入了解、体验产品，请联系我们的产品咨询团队：
              </div>
              <div class="phone">
                咨询电话：400 1853 168
              </div>
            </div>
            <div class="threeBox" style="margin:0 8px;">
              <img style="width:30px;height:25px;" src="../../../static/newImage/consult.png" />
              <div class="type">
                &nbsp;售后咨询
              </div>
              <div class="meCenter">
                如果您在使用过程中遇到任何问题，我们的专业团队都会及时为您提供帮助，请联系：
              </div>
              <div class="phone">
                咨询电话：400 1853 168
              </div>
            </div>
            <div class="threeBox">
              <img style="width:30px;height:25px;" src="../../../static/newImage/consult.png" />
              <div class="type">
                &nbsp;人才引进
              </div>
              <div class="meCenter">
                发挥潜能，施展才华，创造成功。想深入了解，请联系我们：
              </div>
              <div class="phone">
                咨询电话：400 1853 168
              </div>
            </div>
          </div>
          <!-- 地图api -->
          <MapContainer :data="center" style="margin-top: 25px;" />
          <div class="site">
            <div v-for="list in sitelist" :key="list.id" class="siteName">
              <div style="width: 600px;" @click="siteClick(list.id)" :class="{ site_sty: site_id === list.id }">
                <div class="mapName">{{ list.name }}</div>
                <div style="width:26px;height:1px; margin:2px 0 40px 0;">
                  _____
                </div>
                <div class="mapSite">
                  {{ list.site }}
                </div>
                <div class="mapPhone">
                  {{ list.telephone }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="" :visible.sync="showVideo" :show-close="false" width="70vw" top="5vh" :append-to-body="true"
      :before-close="() => {
        showVideo = false;
        this.$nextTick(() => {
          this.$refs['myvideo'].pause();
        });
      }
        ">
      <video ref="myvideo" controls preload="auto" width="100%">
        <source src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/45f99c97b623409e93a9e39efb526c89.mp4" type="video/mp4" />
      </video>
      <i class="el-icon-close"
        style="position: absolute;top: 40px;right: 2px;font-size: 22px;color: #fff;background-color: #000;border-radius: 50%;padding: 10px;font-weight: bold;cursor: pointer;"
        @click="hideVideo"></i>
    </el-dialog>
    <div class="video_show" v-show="showVideo1" @click="cancelVideo">
      <video controls preload="auto" width="100%" ref="myvideo1">
        <source src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-06-05/45f99c97b623409e93a9e39efb526c89.mp4" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script>
import store from "@/store/store.js";
import { getAccessToken, getSignature } from '@/api/index'
export default {
  name: "contact_us",

  data() {
    return {
      body_id: 1,
      right_year: 2023,
      show: false,
      show_year: true,
      site_id: 1,
      roll: 0,
      scrollTop: 0,
      scroll: false,
      center: [120.503875, 30.091548],
      clickId: 1,
      list: [
        {
          id: 1,
          title: "企业介绍",
          content:
            "中峪日用品交易市场有限公司简称《中峪交易市场》。中峪交易市场于2021年2月正式批复成立的日用品交易市场，公司坐落于环境优化、人文底蕴浓厚的浙江绍兴市柯桥区；企业以产融孵化、促进商品流通内需、帮助企业去库存、刺激日用品市场消费，独创全新批零交割商业架构，服务于全国各中小型实体生产企业。现在中峪交易市场拥有一个团结、高效、成熟的管理团队，通过科学严谨的现代化管理，使企业走上了高速发展的快车道。在“以人为本、团结敬业，自强创新”的企业精神鼓舞下，坚持“永恒品质，超然卓越”的经营宗旨，以高精品质创品牌、以诚信服务谋共赢，不断满足和超越客户的期望。",
          image: "../../../static/newImage/编组 13.png",
        },
        {
          id: 2,
          title: "企业文化",
          content:
            "中峪日用品交易市场有限公司简称《中峪交易市场》。中峪交易市场于2021年2月正式批复成立的日用品交易市场，公司坐落于环境优化、人文底蕴浓厚的浙江绍兴市柯桥区；企业以产融孵化、促进商品流通内需、帮助企业去库存、刺激日用品市场消费，独创全新批零交割商业架构，服务于全国各中小型实体生产企业。现在中峪交易市场拥有一个团结、高效、成熟的管理团队，通过科学严谨的现代化管理，使企业走上了高速发展的快车道。在“以人为本、团结敬业，自强创新”的企业精神鼓舞下，坚持“永恒品质，超然卓越”的经营宗旨，以高精品质创品牌、以诚信服务谋共赢，不断满足和超越客户的期望。",
        },
        {
          id: 5,
          title: "联系我们",
          content:
            "中峪日用品交易市场有限公司简称《中峪交易市场》。中峪交易市场于2021年2月正式批复成立的日用品交易市场，公司坐落于环境优化、人文底蕴浓厚的浙江绍兴市柯桥区；企业以产融孵化、促进商品流通内需、帮助企业去库存、刺激日用品市场消费，独创全新批零交割商业架构，服务于全国各中小型实体生产企业。现在中峪交易市场拥有一个团结、高效、成熟的管理团队，通过科学严谨的现代化管理，使企业走上了高速发展的快车道。在“以人为本、团结敬业，自强创新”的企业精神鼓舞下，坚持“永恒品质，超然卓越”的经营宗旨，以高精品质创品牌、以诚信服务谋共赢，不断满足和超越客户的期望。",
        },
      ],
      certificate: [
        {
          id: 1,
          text: "高新技术企业证书",
        },
        {
          id: 2,
          text: "高新技术企业证书",
        },
        {
          id: 3,
          text: "高新技术企业证书",
        },
      ],
      sitelist: [
        {
          id: 1,
          name: "浙江（总部）",
          site:
            "地址:浙江省绍兴市柯桥区金柯大道金昌国际金融中心(金柯大道东100米)2楼207号",
          telephone: "电话:400-185-3168",
        },
      ],
      isVisible: true,
      showVideo: false,
      showVideo1: false,
      haveTitle: true,
      imgIndex: 0
    };
  },

  mounted() {
    if (document.getElementsByClassName('nav_1')[0] && document.getElementsByClassName('nav_1')[0].parentElement.tagName == 'BODY') {
      document.getElementsByClassName('nav_1')[0].remove()
    }
    this.$parent.nav_id = 5;
    localStorage.setItem("nav_id", this.$parent.nav_id);
    this.$parent.home_icon = false;
    if (this.$route.params.dingwei) {
      this.title_btn(5);
    }
    // 视频
    document.body.append(document.getElementsByClassName('video_show')[0])
    // 滚动
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    } else if (/(Android)/i.test(navigator.userAgent)) {
    } else {
      window.addEventListener("scroll", this.changeTitle)
    }
    this.getWXInfo()
  },

  methods: {
    getWXInfo() {
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp
        const nonceStr = res.data.noncestr
        const signature = res.data.signature
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxad8dd02ae97d307f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: '中峪企业介绍', // 分享标题
            desc: '浙江中峪控股集团有限公司 是一家涉足商贸、拍卖交易所、职业培训学校、中医大健康、医疗、生物制药、网络科技、养老产业、金融等十大闭环产业的集团化公司，集团以国家政策为导向，以大众创业，万众创新为初心，跨界融合、科技创新、数字经济、跨产业赋能、多元化经营、服务于各生产企业的同时，为市场培育孵化更多优质的人才。同时紧跟国家战略部署，以乡村振兴为战略目标；以传承弘扬发展中医文化为使命，把中医精髓代代相传并发扬光大，普惠大众；以振兴地区职业技能，培育多元化技术人才，助推就业问题；为国家大健康战略规划真正落地生根普及，为提升老年人生活品质等民生领域，贡献自己的力量。', // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-07-08/269d349bef54469fb60ca528f15eca00.jpg', // 分享图标
          }
          wx.updateAppMessageShareData(obj)
          wx.updateTimelineShareData(obj)
        });
      })
    },
    cancelVideo() {
      this.showVideo1 = false;
      this.$refs["myvideo1"].pause();
    },
    changeTitle() {
      if (window.scrollY > 780) {
        if (this.haveTitle) {
          const html = this.$refs['title']
          if (html) {
            document.body.append(html)
          }
          this.$refs['title'].style = 'display: flex'
          document.getElementsByClassName('titleinside_child')[0].style = 'opacity: 0'
          this.haveTitle = false
        }
      } else if (window.scrollY < 700) {
        if (!this.haveTitle) {
          document.getElementsByClassName('titleinside_child')[0].style = 'opacity: 1'
          this.$refs['title'].style = 'display: none'
          this.haveTitle = true
        }

      }
    },
    hideVideo() {
      this.showVideo = false;
      this.$nextTick(() => {
        this.$refs["myvideo"].pause();
      });
    },
    showVideoFun() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        this.showVideo1 = true
        this.$nextTick(() => {
          this.$refs["myvideo1"].play();
        });
      } else if (/(Android)/i.test(navigator.userAgent)) {
        this.showVideo1 = true
        this.$nextTick(() => {
          this.$refs["myvideo1"].play();
        });
      } else {
        this.showVideo = true;
        this.$nextTick(() => {
          this.$refs["myvideo"].play();
        });
      }
    },
    title_btn(v) {
      this.clickId = v
      this.body_id = v;
      this.show = false;
      this.show_year = true;
      switch (this.body_id) {
        case 1:
          window.scrollTo(0, 760);
          break;
        case 2:
          window.scrollTo(0, 2200);
          break;
        case 5:
          window.scrollTo(0, 2920);
          break;
        default:
          break;
      }
    },
    right_year_btn(v) {
      this.right_year = v;
      //   console.log(v);
    },
    unfold() {
      this.show = !this.show;
      this.show_year = !this.show_year;
    },
    // 地图跳转
    siteClick(v) {
      this.site_id = v;
      //   console.log(this.site_id, "this.site_id");
      switch (this.site_id) {
        case 1:
          //   this.center = [120.503618, 30.091776];
          //   console.log(store.state.center);
          this.$store.commit("centerChange", this.site_id);
          this.$emit("event-from-parent", store.state.center);
          break;
        case 2:
          this.$store.commit("centerChange", this.site_id);
          this.$emit("event-from-parent", store.state.center);
          break;
        case 3:
          //   window.scrollTo(0, 1868.1);
          break;
        case 4:
          //   window.scrollTo(0, 2444.3);
          break;
        case 5:
          //   window.scrollTo(0, 3092.1);
          break;
        default:
          break;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.changeTitle)
    this.showVideo1 = false
    this.$refs["myvideo1"].pause();
    document.getElementsByClassName('nav_1')[0].remove()
  }
};
</script>

<style scoped lang="scss">
.video_show::before {
  content: " ";
  width: 200vw;
  height: 200vh;
  background-color: #00000062;
}

.video_show {
  position: fixed;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  video {
    height: 100%;
    width: 100%;
  }
}

.box {
  height: 100%;

  .nav_ {
    height: 70px;
    display: flex;
    padding: 0 210px;
    font-size: 20px;
    line-height: 70px;
    justify-content: space-between;
    border-bottom: 1px solid #d8d8d8;

    .from_list {
      width: 245px;
      text-align: center;
      cursor: pointer;
      /* 更改鼠标样式为手 */
      position: relative;

      .click_sty {
        color: red;
      }
    }
  }
}

.play_video {
  position: absolute;
  z-index: 12;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  top: 50%;
  left: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: rgba(255, 255, 255, 0.3);
}

.play_video::after {
  content: " ";
  width: 0;
  height: 0;
  border-left: 26px solid #ffffff;
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
  position: absolute;
  top: 18px;
  left: 28px;
}

::v-deep .el-dialog {
  background: transparent;
  box-shadow: none;
}

.barnner {
  overflow: hidden;
  background-image: url("~../../../static/newImage/bannerxiao.jpg");
  width: 100%;
  height: 520px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}

.boundary {
  padding: 20px 0;
  max-width: 1240px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  position: relative;
  /* background: red; */
}

.body_right {
  width: 100%;
  flex: 1;
  background: #fff;
  padding: 0 20px;
}

.body_title {
  padding: 0 10px;
  height: 60px;
  cursor: pointer;
  font-size: 14px;
  line-height: 60px;
  text-align: left;
}

.body_click {
  color: #000;
  font-size: 18px !important;
  position: relative;
  font-weight: bold;
}

.body_click::after {
  content: "";
  height: 55px;
  width: 4px;
  background-color: #e1251b;
  position: absolute;
  top: 0px;
  right: 0;
}

.imgs {
  width: 100%;
  height: 380px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.imgs_item {
  transition: width 0.2s ease-in-out;
  width: 180px;
  object-fit: cover;
  cursor: pointer;
  /* 添加过渡效果 */
}

.imgs_tips {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.imgs_tips_item {
  text-align: center;
  transition: width 0.2s ease-in-out;
  width: 180px;
  cursor: pointer;
  overflow: hidden;

  .imgs_tips_item_bg {
    background-color: rgba(225, 21, 21, .8);
    color: #fff;
    margin: 0 auto;
    width: max-content;
    padding: 0 10px;
    line-height: 34px;
    border-radius: 5px;
  }
}

// .imgs_item:hover,
// .imgs_tips_item:hover {
//   width: 452px;
// }

.active {
  width: 452px;
}

.right_list {
  margin-bottom: 48px;
  cursor: pointer;
  color: #181818;
  opacity: 0.45;
}

.right_sty {
  font-size: 20px;
  color: #000;
  opacity: 1;
}

.certificate_sty {
  display: flex;
  justify-content: space-around;
}

.contact_address {
  width: 100%;
  height: 240px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.threeBox {
  /* width: 400px;
  height: 250px; */
  width: 100%;
  height: 75%;
  padding: 28px;

  background-color: #fdf4f3;
}

.site {
  display: flex;
  margin-top: 40px;
  cursor: pointer;
  flex-wrap: wrap;
}

.siteName {
  flex: 0 0 calc(35% - 10px);
  margin-right: 100px;
}

.mapName {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

.mapSite {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 8px;
}

.mapPhone {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-bottom: 32px;
}

.site_sty {
  color: #e1251b;
}

.type {
  display: inline-block;
  position: relative;
  top: -8px;
  line-height: 33px;
  font-size: 24px;
  margin-bottom: 32px;
}

.meCenter {
  font-size: 16px;
  font-weight: 400;

  color: #181818;
  line-height: 22px;
  margin-bottom: 24px;
}

.phone {
  font-size: 16px;
  font-weight: 400;
  color: #181818;
  line-height: 22px;
}
</style>
